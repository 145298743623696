import React, { useState, useEffect } from 'react'
import oddslib from 'oddslib'

const ODDS_FORMATS = [
    { id: `decimal`, default: true, label: `Decimal` },
    { id: `moneyline`, label: `Money Line` },
    { id: `hongKong`, label: `Hong Kong` },
    { id: `impliedProbability`, label: `Implied Probability` },
    { id: `fractional`, label: `Fractional` },
    { id: `malay`, label: `Malay` },
    { id: `indonesian`, label: `Indonesian` },
]

const OddsFormatPicker = ({ onChange, value, except = [] }) => (
    <select
        className="select is-large"
        onChange={onChange}
        value={
            value ||
            ODDS_FORMATS.find((i) => {
                return i.default === true
            }).id
		}
		style={{
			padding: `0 .5rem`,
		}}
    >
        {ODDS_FORMATS.filter(({ id }) => !except.includes(id)).map(
            ({ id, label }) => (
                <option key={id} value={id}>
                    {label}
                </option>
            )
        )}
    </select>
)

const formatMoney = (num) =>
    new Intl.NumberFormat(`en-US`, {
        style: 'currency',
        currency: 'USD',
    }).format(num)

const OddsConverter = () => {
    const [stake, setStake] = useState(100)
    const [returns, setReturns] = useState(150)
    const [profits, setProfits] = useState(50)
    const [value, setValue] = useState(`1.5`)
    const [fromFormat, setFromFormat] = useState(`decimal`)
    const [toFormat, setToFormat] = useState(`fractional`)
    const [odds, setOdds] = useState(`1/2`)

    useEffect(() => {
        convertOdds()
        computeWager()
    }, [value, fromFormat, toFormat, stake])

    const computeWager = () => {
        try {
			const conversion = oddslib.from(fromFormat, parseFloat(value))
			const result = conversion.to(`decimal`)
			const returnsValue = result * stake
			const profitsValue = returnsValue - stake
			setReturns(returnsValue)
			setProfits(profitsValue)
		} catch (error) {
			console.warn(`error computeWager`, error)
		}
    }

    const convertOdds = (e) => {
        e && e.preventDefault()

        try {
            const conversion = oddslib.from(fromFormat, parseFloat(value))
			let result = conversion.to(toFormat, { precision: 2 })
			if (toFormat === `impliedProbability`) {
				result = `${result*100}%`
			}
            console.info(
                `Converting "${value}" from "${fromFormat}" to "${toFormat}: "${result}".`,
                {
                    value,
                    fromFormat,
                    toFormat,
                    result,
                }
            )
            setOdds(result)
        } catch (error) {
			console.warn(`error convertOdds`, error)
			alert(error.message)
        }
    }

    return (
        <div>
            <div className="columns is-desktop">
                <div className="column">
                    <div className="field">
                        <label className="label">Original Odds</label>
                        <div className="control">
                            <OddsFormatPicker
                                value={fromFormat}
                                onChange={(e) => setFromFormat(e.target.value)}
                            />
                        </div>
                        <p className="help">Pick the original format of odds.</p>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
						<label className="label">&nbsp;</label>
                        <div className="control">
                            <input
                                className="input is-large"
                                value={value}
                                onChange={(e) => e.target.value.length > 0 ? setValue(e.target.value) : "1.5"}
                            />
                        </div>
                        <p className="help">Enter the original value.</p>
                    </div>
                </div>
                <div
                    className="column is-one-quarter"
                    style={{
                        alignSelf: `center`,
                        textAlign: `center`,
                        fontSize: `300%`,
                    }}
                >
                    <p>
                        <strong>&raquo; TO &raquo;</strong>
                    </p>
                </div>
                <div className="column">
                    <div className="field">
                        <label className="label">Converted Odds</label>
                        <div className="control">
                            <OddsFormatPicker
                                value={toFormat}
                                onChange={(e) => setToFormat(e.target.value)}
                            />
                        </div>
                        <p className="help">Pick the desired odds format.</p>
                    </div>
                </div>
                <div className="column">
                    <div className="field">
					<label className="label">&nbsp;</label>
                        <div className="control">
                            <input
                                className="input is-large has-background-primary-light has-text-weight-semibold"
                                value={odds}
                                readOnly={true}
                            />
                        </div>
                        <p className={"help"}>The converted odds.</p>
                    </div>
                </div>
            </div>
            <hr />
            <div>
                <p>
                    If you bet{' '}
                    <input
                        type="number"
                        step={0.5}
                        min={1}
                        value={stake}
                        onChange={(e) => setStake(e.target.value)}
                    />{' '}
                    and win, you would receive{' '}
                    <strong>{formatMoney(returns)}</strong> for an effective
                    profit of <strong>{formatMoney(profits)}</strong>.
                </p>
            </div>
		</div>
    )
}

OddsConverter.propTypes = {}

OddsConverter.defaultProps = {}

export default OddsConverter
