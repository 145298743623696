import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import OddsConverter from '../../tools/OddsConverter'

const OddsConverterPage = () => (
    <Layout>
        <SEO title="Odds Converter – US, UK, HK, AUS, Malay &amp; Probability" />
		<section className="hero is-dark">
            <div className="hero-body">
                <div className="container is-fluid">
                    <h1 className="title">Odds Converter</h1>
                    <h2 className="subtitle">
                        An odds format converter that allows you to convert betting odds to and from: Fractional odds, Decimal odds, American odds, Moneyline odds, Malay odds, Indonesian odds, Hong Kong odds, and Implied Probability.
                    </h2>
                </div>
            </div>
        </section>

		<section className="section">
            <div className="container is-fluid">
				<OddsConverter />
            </div>
        </section>

		<section className="section">
			<div className="container">
				<h2 className="title">What are betting odds?</h2>
				<div className="panel">
					<p style={{ padding: `1rem` }}>Betting odds are mathematical representations of the likelihoods of an event, or outcome, occurring. Each culture has its favorite odds format that has been used for decades in horse racing and, more recently, on other <a href="https://betandbeat.com/betting/" target="_blank">online sports betting exchanges</a>.</p>
				</div>
				<h2 className="title">Why are there so many betting odds formats?</h2>
				<div className="panel">
					<p style={{ padding: `1rem` }}>Some cultures affectionate <a href="https://help.smarkets.com/hc/en-gb/articles/214071849-What-are-the-different-betting-odds-formats-" target="_blank">some odds formats more than others</a>. It is a culturally ingrained behavior in regions that hold gambling dear. Additionally, some markets and sports favor some odds formats more than others.</p>
				</div>
				<h2 className="title">Should you know how to read all the different odds formats?</h2>
				<div className="panel">
					<p style={{ padding: `1rem` }}>As a punter, you should most likely know what each format represents, at least have a vague ballpark idea. Yet, generally, gamblers tend to stay within the realm of what they are confident about. And this is valid for the sports and types of bets they are wagering on, but also for the format of the odds displayed by the betting platform, or the betting operator.</p>
				</div>
			</div>
		</section>

    </Layout>
)

export default OddsConverterPage
